import { useState } from "react";
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from '../images/serbius_white.png';

export default function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/signin`, { username, password }, { withCredentials: true });

        const data = response.data;


        if (data.status === 'ok' && data.user) {
          if (data.token) {
              localStorage.setItem('token', data.token); // Store token separately
          } else {
              console.warn('Token not found in response');
          }
          localStorage.setItem('user', JSON.stringify(data.user)); // Store user information
          navigate('/home');
        } else {
            alert(data.message || 'An error occurred');
        }
    } catch (err) {
        if (err.response) {
            setError(err.response.data.message || 'An error occurred');
            alert(err.response.data.message || error);
        } else if (err.request) {
            setError("No response from server. Please try again later.");
            alert(error);
        } else {
            setError("An error occurred. Please try again.");
            alert(error);
        }
    }
};



  return (
    <div className="container bg-gradient-to-br from-slate-950 to-purple-950 flex flex-col min-w-full min-h-screen justify-center items-center place-content-center">
      <img src={Logo} alt='Serbius Logo' className='w-1/3 my-5' />

      

      <form onSubmit={handleSubmit} className="container md:w-1/2 sm:w-11/12 bg-slate-300 rounded-2xl grid grid-cols-2 lg:grid-rows-1 sm:grid-rows-3 justify-center items-center content-center">
        <div className="rounded-s-2xl lg:col-span-1 sm:col-span-2 sm:row-span-1 rounded-e-none md:w-full sm:w-full container flex justify-center items-center text-center">
          <h1 className="text-5xl text-black min-w-full text-center  font-sans font-black">Sign In</h1>
        </div>
        <div className="container lg:col-span-1 sm:row-span-2 bg-white sm:col-span-2 flex p-5 flex-col rounded-b-2xl gap-2">
          <div className="container flex flex-auto h-16 justify-center gap-5 border-solid border-2 border-purple-300">
            <input
              className="text-center w-full"
              placeholder="Username"
              name="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="container flex flex-auto h-16 justify-center gap-5 border-solid border-2 border-purple-300">
            <input
              className="text-center w-full"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <div className="container flex flex-auto h-16 justify-end items-center gap-5">
            <button type="submit" className="w-1/2 text-white h-14 bg-gradient-to-br from-purple-950 to-purple-600 hover:bg-gradient-to-lr hover:from-purple-600 hover:to-purple-800 hover:shadow-lg hover:shadow-slate-700">
              Sign In
            </button>
          </div>
        </div>
      </form>
      <div className='container flex md:justify-between sm:justify-center md:w-1/2 2xl:w-1/4 lg:w-1/4 sm:w-11/12 my-5'>
        <p className='text-white'>© 2022-2024 Serbius Australia. V1.2.0</p>
      </div>
    </div>
  );
}
