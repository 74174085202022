import * as React from "react";
import Home from "./components/Home";
import { Routes, Route } from "react-router-dom";
import SignIn from "./components/SignIn";



import './CSS/master.css'
import './CSS/tailwind.css'


function App() {
  

  

  return (
      <Routes>
        <Route path="/home" Component={Home}/> 
        <Route path="/" Component={SignIn}/> 
      </Routes>
  );
}

export default App;